<template>
    <div class="height-to-window">
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">

            <h4 class="mt-2">{{$t('auth.confirm_title')}}</h4>
            <!-- alert confirm -->
            <b-alert
                :show="response.message != null"
                class="mb-0 mt-2"
                variant="success"
            >
                <div v-html="response.message"></div>
            </b-alert>
        </b-overlay>
        <!-- alert error -->
        <b-alert
            :show="errors.confirmMessage != null"
            class="mb-0"
            dismissible
            variant="danger"
            @dismissed="resetErrors"
        >
            <div v-html="formatConfirmError"></div>
        </b-alert>
    </div>
</template>

<script>
export default {
    name: "Confirm",
    components: {},
    props: {},
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            uri: {
                main: `api/v1/auth`,
            },
            errors: {
                confirmMessage: null,
            },
            response: {
                message: null,
            },
        }
    },
    mounted() {
        this.confirm();
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        resetErrors() {
            this.errors.confirmMessage = null;
        },
        // <--  -->
        confirm() {
            let token = this.$route.query.key;
            // <-- axios -->
            this.showOverlay(true);

            this.axios.post(this.uri.main + '/confirm',
                {
                    'locale': this.$i18n.locale,
                    'token': token
                })
                .then((response) => {
                    this.response.message = this.$t('auth.confirm_message_success');
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.confirmMessage = error;
                    this.showOverlay(false);
                })
                .finally(function () {
                });
        }
    },
    computed: {
        // <-- errors -->
        formatConfirmError() {
            let message = '';

            let error = this.errors.confirmMessage;
            if (error === null) {
                return null;
            }
            // <-- error.message -->
            if (error.message !== undefined) {
                message = error.message;
            }
            // <-- error.errors -->
            if (error.errors !== undefined && typeof error.errors === 'object') {
                for (let key in error.errors) {
                    message += '<br><b>' + key + ':</b> ' + error.errors[key][0];
                }
            }
            // <--  -->
            return message;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
